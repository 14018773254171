<template>
  <div class="ma-2 ma-sm-6 ma-md-0 pt-4">
    <div class="page-center">
      <v-text-field
        outlined
        v-model="searchlable"
        label="Search questions"
        append-icon="search"
        class="ma-4 elevation-0"
        height="15"
      ></v-text-field>
    </div>
    <template>
      <div class="pa-0 pa-md-4 page-center faq-section">
        <v-expansion-panels>
          <template v-if="filteredlables.length > 0">
            <v-expansion-panel
              class="mb-4 pa-0"
              elevation="0"
              v-for="result in filteredlables"
              :key="result.id"
            >
              <v-expansion-panel-header>
                <strong
                  :class="{
                    'primary--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  style="line-height: 1.5"
                  >{{ result.question }}</strong
                >
                <template v-slot:actions>
                  <v-icon color="primary">$expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                v-html="result.answar"
                class="pa-4 pt-0"
              >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
          <template v-else>
            <h6>No results found</h6>
          </template>
        </v-expansion-panels>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  data: () => ({
    searchlable: "",
    allresult: [
      {
        id: 1,
        question: "How can I be a part of Focts?",
        answar:
          "It is easy to be a part of Focts. Create your own company and register with Focts or get an invite from the company if you are a user.",
      },
      {
        id: 2,
        question: "What will be my role in Focts?",
        answar:
          "There are three roles. One, being registered as the company. Two, being invited as the user by the company and three, if the company promotes one as the manager, then the role allocated will be that  of a manager.",
      },
      {
        id: 3,
        question: "Who can add users to the project/workspace?",
        answar:
          "The company admin or manager, workspace manager and project manager can only add users to a workspace and project.",
      },
      {
        id: 4,
        question: "Why am I not able to see any project in my workspace?",
        answar:
          " Only when you have access or have been assigned to a project will it be reflected on your workspace. Else it will be blank. Contact your company to be added to any particular project.",
      },
      {
        id: 5,
        question: "Why is my project card in red color?",
        answar:
          " The project card turns red when the completion date is nearing or when the date has approached.",
      },
      {
        id: 6,
        question: "Can I update project details?",
        answar:
          "Company admin/ Company manager/ Project manager can update details.",
      },
      {
        id: 7,
        question:
          "Where can I see tasks that require action to be taken by me?",
        answar:
          "A list of tasks and the action required needed are displayed on the tasks view list, along with the priority status and date. My work also shows tasks that require action from you.",
      },
      {
        id: 8,
        question: "Why does it show that I am removed from a project?",
        answar:
          "It is up to the managers or the company owner to add or remove a user from a project. Contact the company to know more.",
      },
      {
        id: 9,
        question: "Can a user create a group chat?",
        answar:
          "No. Only a company manager/owner can create a chat group and add users to the group.",
      },
      {
        id: 10,
        question: "What is a feature module?",
        answar:
          "Any suggestions to be given to developers can be done through a feature module. These suggestions will help developers to improve an application.",
      },
      {
        id: 11,
        question: "Who can delete a project/workspace?",
        answar:
          "A company owner/manager can delete a workspace if they are the ones who created it. On the other hand, a project can be deleted by workspace manager, company manager and owner.",
      },

      {
        id: 12,
        question: "Why is there a Start time for a task?",
        answar:
          "You can add the start and end times of tasks. This will be your total working time for that particular task, and this time can be viewed in project timer or My Time.",
      },
      {
        id: 13,
        question: "Why is the other user not visible in a task?",
        answar:
          "The user should be assigned to the particular task before starting that task.",
      },
      {
        id: 14,
        question: "Why am I not able to create a Workspace?",
        answar: "You must be a user. Users cannot create a workspace.",
      },

      {
        id: 15,
        question:
          "If I cancel the subscription, will I not be able to use the Focts?",
        answar:
          "You will still be able to use the Focts until your subscription date expires. For example, if your plan ends on Jan 10th and you have cancelled your subscription on Jan 5th, you can enjoy all the benefits of Focts till Jan 10th. You will have to renew or buy a new subscription beyond that date.",
      },
      {
        id: 16,
        question: "How can I apply for leave?",
        answar:
          "You can apply for leave in the Holiday Management section. A company manager or admin can also apply for leave on your behalf.",
      },
      {
        id: 17,
        question: "What is my applied leave status?",
        answar:
          "Your leave request will either be approved or disapproved by the company admin/manager. Any status update on your leave request will be notified to you through email and inbox.",
      },
      {
        id: 18,
        question: "What is custom status and priority?",
        answar:
          "Work management can be simplified with presets for status and priorities. A project can have a custom status and priority if it is required. Statuses and priorities can be given a different color based on their priority. The status/priority can also be deleted, edited, and colored.",
      },
      {
        id: 19,
        question: "What are custom task types?",
        answar:
          "Customize tasks types with different icons. We can add a task type and assign an icon to it. Edit or delete a task type anytime as required.",
      },
      {
        id: 20,
        question: "What is a custom field?",
        answar:
          "Custom fields in the project setting have text, date, and dropdown fields. Effortlessly use that as per your need. Easily add or delete any of these settings during the project.",
      },
      {
        id: 21,
        question:
          "I have completed the task. I somehow forgot to add the start and stop times. How can the company manager know my working time for the task?",
        answar:
          "No worries. Once you complete the task, you can manually enter the working time for that task using the add option. The add function for the task will only be available if you haven't added the start and end times before.",
      },
      {
        id: 22,
        question:
          "Is it possible that a company could have more than one company manager?",
        answar:
          "Yes. More than one company manager is possible for a single company. The company owner has to make users as managers in that case and also invite a new manager.",
      },
      {
        id: 23,
        question:
          "I am a company owner, and I have subscribed for Focts. Do my manager and employees need to subscribe too?",
        answar:
          "No. Only company owners can buy subscriptions based on the number of users that the company has.",
      },

      {
        id: 24,
        question:
          "My payment card is going to expire today. Can I still go ahead with the subscription?",
        answar:
          "No. You can’t buy a subscription with an expired card. You can easily add a new card in the Profile -> Payment Detail section and complete the transaction.",
      },
      {
        id: 25,
        question:
          "If I remove a user from the company, can I include him again with the previous role?",
        answar: "Yes. You can, but he will be acting as a new user.",
      },
      {
        id: 26,
        question: "Why does my task profile show 0 tasks and 0% completed?",
        answar:
          "Even if you create a task, the action required by you for that task should be filled by you. Only then will it be visible in the task profile.",
      },
      {
        id: 27,
        question: "How can I contact Focts?",
        answar:
          "You can email us &nbsp; <a href='mailto:support@focts.com' class='text-decoration-none'>support@focts.com</a>. We will get back to you as soon as we can.",
      },
      {
        id: 28,
        question: "Can I shift a task from one project to another?",
        answar:
          "Yes. Only company admin and project manager can able to shift task to another project.",
      },
      {
        id: 29,
        question: "Who can create project?",
        answar:
          "Company admin or manager and workspace manager can only able to create project.",
      },
      {
        id: 30,
        question:
          "Can i add more users beyond the subscription which I have subscribed for the number of seats?",
        answar:
          "Yes, you can upgrade/downgrade your subscription with number of seats required by you anytime.",
      },
    ],
  }),
  mounted() {
    // document.body.classList.remove("has-scroll");
  },
  computed: {
    filteredlables() {
      if (this.searchlable) {
        return this.allresult.filter((reusls) => {
          return reusls.question
            .toLowerCase()
            .includes(this.searchlable.toLowerCase());
        });
      } else {
        return this.allresult;
      }
    },
  },
  methods: {},
};
</script>
<style scoped>
.helpcard.v-card.v-sheet.theme--light {
  background-color: #e3f2fd;
}
.v-slide-group.v-item-group > .v-slide-group__prev {
  display: none !important;
}
.keycolumn {
  color: #2f71de;
}
</style>
