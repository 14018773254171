<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        Roles Management
      </h3>
    </div>
    <div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/add-user.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Overview</h3>
              <p class="landing-single-content">
                Roles management is about adding a new user to your company.
                Invited users will display on the invitations section. Company
                admin/managers have the ability to activate or deactivate the
                users from the company.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/add-user.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Invite a user</h3>
              <p class="landing-single-content">
                To invite the user, choose the user type, either a manager or a
                user. Then enter the email address of the user. The new user is
                added. This can be done by company owners and managers. Added
                user will be displayed on the Invitations tab.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/invite-user.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/active-inactive.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Active & Inactive users</h3>
              <p class="landing-single-content">
                Active users are users who are currently working under your
                company. You can deactivate a user if you want to remove a user
                from the company. Note that the user will be removed from your
                company but their data, for e.g., their time spent on a task and
                other related information will not be removed from the company.
                This history gives the manager or admins to easily track any
                necessary past information
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/active-inactive.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <div class="roles-table-scroll">
            <table>
              <tr>
                <th>Features</th>
                <th>Company admin</th>
                <th>Company manager</th>
                <th>Company user</th>
                <th>Workspace manager</th>
                <th>Workspace user</th>
                <th>Project manager</th>
                <th>Project user</th>
              </tr>
              <tr>
                <td>Create and Delete workspace</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Add and delete user in workspace</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Edit workspace name</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Promote workspace user to manager</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Add, edit and delete template</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Create and delete project</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Edit project details</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Project files page</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
              </tr>
              <tr>
                <td>Add and delete user in project user page</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Promote project user to manager</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Add, edit and delete project settings</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Add project value to the project</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Update time in project timer</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Create task</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
              </tr>
              <tr>
                <td>Delete task</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>
                  Edit task title, description, create to-do list, delete to-do
                  list, change priority, status, task type, update date, action
                  required, time estimate, assigned user, complete task, labels,
                  custom fields, edit comments, pinned the task, drag and drop
                  the task
                </td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
              </tr>
              <tr>
                <td>Start/end timer, update timer and edit/delete timer</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
              </tr>
              <tr>
                <td>Create subtask and convert to normal task</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
              </tr>
              <tr>
                <td>Shift task to other project</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Invite user or manager</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Deactivate company user from the company</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Deactivate company manager from the company</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Activate user or manager from the company</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Change shift time for company user</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Change shift time for other manager in a company</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Promote company user to manager</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>
                  Update and delete time in the my time for same user or manager
                </td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
              </tr>
              <tr>
                <td>
                  Update and delete time in the my time for other user or
                  manager
                </td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Create and delete group chat and add user to group</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Apply leave request</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
              </tr>
              <tr>
                <td>Approve/Disapprove and delete leave request</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Apply leave for other user or manager in a company</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
                <td><v-icon color="red">close</v-icon></td>
              </tr>
              <tr>
                <td>Feature modules</td>
                <td>
                  <v-icon color="primary">check</v-icon>
                </td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
                <td><v-icon color="primary">check</v-icon></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts Roles Management - Effortless User Integration",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Simplify user integration with Focts Roles Management. Add new users, view invitations, and activate/deactivate users. Company admins and managers have full control for seamless user management.",
      },
      {
        name: "keywords",
        content:
          "Focts, Roles Management, User Integration, New Users, Invitations, Activate Users, Deactivate Users, Company Admins, Managers, Seamless Management, Effortless Integration",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
.landing-single-back table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.landing-single-back td,
.landing-single-back th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
}

/* .landing-single-back tr:nth-child(even) {
    background-color: #dddddd;
  } */
</style>
