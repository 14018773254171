<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        Customization
      </h3>
    </div>
    <div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Status</h3>
              <p class="landing-single-content">
                Define the workflow of the project by adding new statuses.
                Custom statuses allow you to add several statuses to a project,
                as you may need more than the predefined statuses present for
                your project. We provide predefined statuses, not started, in
                progress, in review, and closed, with "not started" being the
                default value when a task is created. Predefined statuses cannot
                be deleted. It is possible to edit the name and delete the
                status you added during anytime of the project. Different color
                indications can be added to the status. The settings page can be
                accessed only by the project manager or Company admin. Each task
                in that project will be informed by the status presented here.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/setting-status.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/setting-prorities.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Priority</h3>
              <p class="landing-single-content">
                Task priority refers to the process of deciding which tasks to
                tackle first, based on their relative importance and urgency.
                Add priorities to any specific tasks in a project as low,
                medium, high, emergency. We can make any one of the priorities
                as default which will be the default priority while we create a
                task on the project. Custom priorities allow you to add several
                priorities to a project, as you may need more than the
                predefined priorities present for your project. The ability to
                create, edit, or delete custom priorities during anytime of the
                project. Predefined priorities cannot be deleted. A custom
                priority can be given a color which will be reflected on each
                task on the task page. The settings page can be accessed only by
                the project manager or Company admin.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/setting-prorities.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Task Type</h3>
              <p class="landing-single-content">
                Customize different tasks with different icons by using task
                type. The project settings page has several custom options. Task
                types are one among them, which helps you determine what kind of
                tasks can be present in your project. We provide a predefined
                set of task types, namely "task," "issue," and "idea. A task
                type can be edited or deleted at any time during the project.
                Instead of changing the color, select an icon. One can add many
                task types with predefined icons and mark any as the default
                value. The settings page can be accessed only by the project
                manager or Company admin.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/setting-task-type.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/manage-labels.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Labels</h3>
              <p class="landing-single-content">
                Project settings page has several custom options. Labels are one
                among them. Custom Label allow you to add several labels to a
                project by clicking “Add Label”. The ability to edit, or delete
                labels during anytime of the project. Custom labels can be added
                to the tasks of a project. The settings page can be accessed
                only by the project manager or Company admin.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/manage-labels.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Custom field</h3>
              <p class="landing-single-content">
                Customize the settings of your project to best describe it. In
                that Custom fields in the project setting have text, date, and
                dropdown fields. Easily add or delete any of these settings
                during any time of the project. Effortlessly use that as per
                your need. As long as any custom fields have the "required"
                label, they must be filled out when creating tasks on the
                project which include that custom field. The settings page can
                be accessed only by the project manager or company admin.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/setting-custom-fields.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts Project Workflow - Define with Custom Statuses",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Optimize project workflow with Focts. Add custom statuses, predefined options, and different color indications. Easily edit, delete, and manage statuses. Project managers and company admins have exclusive access to the settings page for efficient workflow control.",
      },
      {
        name: "keywords",
        content:
          "Focts, Project Workflow, Custom Statuses, Predefined Statuses, Task Management, Workflow Optimization, Color Indications, Edit Status, Delete Status, Project Managers, Company Admins, Settings Page, Efficient Workflow Control",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
