<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        Pricing
      </h3>
    </div>

    <div class="task-management-section">
      <div class="features-section pt-10 pb-10">
        <div class="page-centerr">
          <v-row class="justify-center">
            <v-col
              cols="12"
              sm="12"
              md="3"
              class="ma-1 pa-1 ma-md-3 pa-md-3 rounded-lg elevation-2 white pricing-card"
            >
              <v-card elevation="0">
                <div class="text-center font-weight-medium text-h6 pt-5 pb-3">
                  MONTHLY SUBSCRIPTION
                </div>
                <div class="row flex-column align-center justify-center">
                  <div class="d-flex align-center">
                    <h6 class="pr-2 font-weight-bold mt-5">USD</h6>
                    <h2 class="pl-2 font-weight-bold my-5">
                      7 <span class="text-h6">/ user</span>
                    </h2>
                  </div>
                  <div class="pb-2 grey--text">
                    *Above 50 users: <strong>7 USD</strong>
                  </div>
                  <div class="pb-1 grey--text">
                    1-50 users: <strong>10 USD</strong>
                  </div>
                </div>
                <div class="d-flex justify-center my-6">
                  <v-btn
                    class="primary py-6 text-subtitle-1 font-weight-bold"
                    href="/signup"
                    width="80%"
                  >
                    Join now
                  </v-btn>
                </div>
                <ul
                  class="d-flex align-start justify-center flex-column pt-4 subscription-list pl-0"
                >
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Timeline view
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    List view
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Board view
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Calendar view
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Timeline
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Time tracking
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Custom fields
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Attendance management
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Messaging
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Project templates
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    User roles management
                  </li>
                </ul>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="3"
              class="ma-1 pa-1 ma-md-3 pa-md-3 rounded-lg elevation-2 white pricing-card"
            >
              <v-card elevation="0">
                <div class="text-center font-weight-medium text-h6 pt-5 pb-3">
                  YEARLY SUBSCRIPTION
                </div>
                <div class="row flex-column align-center justify-center">
                  <div class="d-flex align-center">
                    <h6 class="pr-2 font-weight-bold mt-5">USD</h6>
                    <h2 class="pl-2 font-weight-bold my-5">
                      60 <span class="text-h6">/ user</span>
                    </h2>
                  </div>
                  <div class="pb-2 grey--text">
                    *Above 50 users: <strong>60 USD</strong>
                  </div>
                  <div class="pb-1 grey--text">
                    1-50 users: <strong>84 USD</strong>
                  </div>
                </div>
                <div class="d-flex justify-center my-6">
                  <v-btn
                    class="primary py-6 text-subtitle-1 font-weight-bold"
                    href="/signup"
                    width="80%"
                  >
                    Join now
                  </v-btn>
                </div>
                <ul
                  class="d-flex align-start justify-center flex-column pt-4 subscription-list pl-0"
                >
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Timeline view
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    List view
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Board view
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Calendar view
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Timeline
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Time tracking
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Custom fields
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Attendance management
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Messaging
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    Project templates
                  </li>
                  <li class="pa-3 black--text">
                    <v-btn x-small color="black" solo icon class="pr-3">
                      <v-icon class="font-weight-bold">check_circle</v-icon>
                    </v-btn>
                    User roles management
                  </li>
                </ul>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="3"
              class="ma-1 pa-1 ma-md-3 pa-md-3 rounded-lg elevation-2 white pricing-card"
            >
              <v-card elevation="0">
                <div class="text-center font-weight-medium text-h6 pt-5 pb-3">
                  CUSTOM PRICING
                </div>

                <div
                  class="pl-4 pr-4 text-left text-body-1"
                  style="line-height: 2"
                >
                  Our custom pricing solutions are tailored to your business’s
                  unique needs. Our team works closely with you to understand
                  your specific objectives and industry landscape. This allows
                  us to design a personalized pricing structure that delivers
                  maximum value, ensuring you pay only for the services
                  essential to your success. Whether you’re a startup or an
                  enterprise, our goal is to create a perfect fit for your
                  organization
                </div>
                <div class="d-flex justify-center my-6">
                  <v-btn
                    class="primary py-6 text-subtitle-1 font-weight-bold"
                    href="/contact-us"
                    width="80%"
                  >
                    Contact Us
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts Pricing - Unlock Your Productivity at the Best Value",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Explore Focts Pricing Plans. Choose the perfect fit for your needs. Affordable, transparent, and packed with features. Elevate your productivity journey with Focts today.",
      },
      {
        name: "keywords",
        content:
          "Focts Pricing, Pricing Plans, Productivity Tools, Affordable Plans, Transparent Pricing, Feature-Packed, Value for Money, Choose Your Plan, Best Value, Elevate Productivity, Explore Now, Get Started",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
.subscription-list {
  list-style-type: none;
}

@media (min-width: 768px) and (max-width: 959px) {
  .pricing-card {
    width: 100%;
    max-width: 500px;
  }
}
@media (max-width: 767px) {
  .pricing-card {
    width: 100%;
    max-width: 300px;
  }
}
</style>
