<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
      id="render-event"
    >
      <h3 class="white--text font-weight-medium text-h3 text-md-h2 text-center">
        Views
      </h3>
    </div>
    <div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">List</h3>
              <p class="landing-single-content">
                Task List displays the tasks in list form. One can quickly add
                the task to any status by clicking "Add task." This view
                provides a drag-and-drop feature to change the task from one
                status to other. You can quickly sort or filter the tasks using
                the filter and sort-by options. Multiple headers can be added to
                the List by using the settings button. Easily change the Task
                type, status, and priorities of tasks. To change the priorities
                and status for multiple tasks simultaneously, click the checkbox
                of the required tasks. Time estimates for all tasks is displayed
                above the list.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/custom-view-list.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/board.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Board</h3>
              <p class="landing-single-content">
                In the board view, tasks are grouped by their project statuses
                into columns. One can create a task on the status present on the
                board. Time estimates for all tasks will be displayed besides
                each status. Mark the task as complete by clicking the tick
                icon. Sort or filter the tasks by using the sort by/filter by
                option. Drag and drop tasks between statuses. One can change
                status of multiple tasks using the checkbox present if they have
                the required permissions. Priority of a task is denoted by the
                color on the left border on each task.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/board.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Table</h3>
              <p class="landing-single-content">
                In the table view, tasks are displayed in rows and columns. The
                tasks in the table are default displayed with a title, action
                required, status, priority, owner and task type columns. Easily
                add a task by clicking the + button. One can easily filter the
                tasks by using the filter option. Multiple columns can be added
                to the table by using the settings button. Sort each column by
                clicking the heading. You can change the status, priority, and
                task type directly from the table without clicking the task.
                Time estimates for all tasks is displayed above the table.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/table.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/gantt-chart.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Gantt Chart</h3>
              <p class="landing-single-content">
                In the Gantt chart view, tasks will be displayed with the blend
                of chart and table. Task names, Action required, start and end
                dates and duration are displayed. Each task row includes a "Task
                Timeline" where you can visually represent the duration of the
                task. Clicking on the task opens up a detailed view of the task.
                The task must have a start/end date to be displayed in the Gantt
                chart view. Sort and filter by option are also available in this
                view. Toggle the "Show task list" button to get a full list of
                tasks in timeline.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/gantt-chart.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center landing-single-back">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Calendar</h3>
              <p class="landing-single-content">
                The tasks will be displayed on the calendar based on each task's
                start/end date. You can add new tasks directly from the calendar
                view. This view will not display tasks without a start/end date.
                One can browse through the months by clicking the left and right
                arrows. A filter option is also provided to filter the tasks
                based on multiple conditions. Ability to filter and
                drag-and-drop tasks can be a powerful way to visualize and
                manage your tasks. Once drag and drop a task onto a new date,
                the new data being the task’s due date. Add a task by hover on a
                particular date.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/calendar-view.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts Task View - Efficient Task Management and Organization",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Experience Focts Task View: Add, organize, and prioritize tasks effortlessly. Use drag-and-drop, sorting, and filtering options for efficient task management. Customize headers, types, statuses, and priorities easily. Time estimates displayed for enhanced productivity.",
      },
      {
        name: "keywords",
        content:
          "Focts, Task List, Efficient Task Management, Task Organization, Drag-and-Drop, Sorting Options, Filtering Options, Customize Headers, Task Types, Task Status, Task Priorities, Multiple Headers, Time Estimates, Productivity, Task Efficiency",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
