<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        WorkSpaces
      </h3>
    </div>

    <div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Overview</h3>
              <p class="landing-single-content">
                Workspaces are primarily used to organize and manage customer
                projects. They serve as dedicated areas where teams can
                collaborate, track project progress, and ensure that customer
                expectations are met efficiently. This centralization of
                project-related data and tools helps businesses provide
                excellent service and easily track all the tasks under a
                workspace. Add or delete workspaces option available to the
                company admin and managers. Easily add or delete a project in
                the workspace. The search option in the workspace section is a
                handy feature that enables users to swiftly locate and access
                specific workspaces in a list. Edit workspace name, inside the
                single workspace, by click "Edit workspace" button.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/Workspace.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/project.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Project</h3>
              <p class="landing-single-content">
                Please find the full summary of a project, the users, project
                timer, tasks, project value, files and settings upon clicking
                this tool. Change the settings of the project or add/delete a
                project. Create a new project and add details to it. The details
                can be edited during any time of the project. The summary of the
                project gives a complete list of all the activities currently
                going on in the project. Easily delete a user from a project.
                Once the user is removed from the project, all the tasks the
                user was assigned should be delegated to a new user.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/project.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Managers</h3>
              <p class="landing-single-content">
                The list of managers in the workspace, will display under the
                "Managers" tab. When there is only one manager in the workspace,
                then a company admin cannot delete the manager, each project
                should have one manager. Company admin and workspace manager can
                able to add or delete manager to the workspace
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/manager.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/users.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Users</h3>
              <p class="landing-single-content">
                The list of users in the workspace, will display under the
                "Users" tab. Company admin and workspace manager can able to add
                or delete users to the workspace. "Make as manager" option is
                available to the workspace user.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/users.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts - Optimize Project Management with Dedicated Workspaces",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Explore Focts, your solution for streamlined project management. Utilize dedicated workspaces for efficient collaboration, progress tracking, and meeting customer expectations. Centralize project data, add/delete workspaces and projects with ease, and enhance productivity. The search feature ensures quick access to specific workspaces. Simplify workspace management by editing names effortlessly.",
      },
      {
        name: "keywords",
        content:
          "Focts, Project Management, Workspaces, Collaboration, Progress Tracking, Customer Projects, Efficient Service, Task Management, Workspace Management, Add/Delete Workspaces, Search Feature, Productivity, Project Data, Edit Workspace Name, Dedicated Areas, Team Collaboration, Customer Expectations, Streamlined Management",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
