<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        Project Management
      </h3>
    </div>

    <div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Summary</h3>
              <p class="landing-single-content">
                The summary highlights the important details about the project.
                The start and end date are displayed on the right top. To assist
                in clear reading and understanding five tabs are displayed.
                Users, completed tasks, incomplete tasks, overdue tasks and
                managers are the five tabs. The recent progress within a project
                is available below with the date for each progress mentioned
                alongside. Easy to comprehend and easy to keep track!
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/Summary.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/custom-view-list.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Tasks</h3>
              <p class="landing-single-content">
                The Task View is a central hub for managing tasks efficiently,
                allowing users to organize, track, and update. The task can be
                viewed in any view format namely Table, List, Board, Gantt Chart
                and Calendar. The sort by option facilitates, the user to sort
                the tasks as per their convenience. Task view settings can
                enhance the flexibility and user-friendliness of your task view,
                based on unique workflows and preferences.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/custom-view-list.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Files</h3>
              <p class="landing-single-content">
                Upload files related to the project as the work progresses.
                Files uploaded can be viewed by anyone in the project. The total
                files in a project are shown at the top. When a file is no
                longer useful, easily delete the file by clicking on the bin
                icon. Having these sorting options in the file table can greatly
                enhance file organization.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/files.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/show-user.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Users</h3>
              <p class="landing-single-content">
                The number of users that are assigned for a particular project
                can be easily viewed. There are two options wherein the managers
                of the project or the users can be displayed. Toggle the options
                to view either of the two or both managers and the users. The
                number of tasks allocated to each user or manager is visible.
                When there is only one manager in the project, then a company
                admin cannot delete the manager, each project should have one
                manager. All the tasks the user or manager was assigned should
                be delegated to the other user in that project while deleting
                the user. The Project manager and Company admin can able to
                delete or add the user or manager anytime of the project.
                Ability to promote a user to a project manager which enables all
                the extended permissions for the user.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/add-user.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Settings</h3>
              <p class="landing-single-content">
                Customize the settings of your project to best describe it.
                Prioritize tasks based on the task types, priorities, and
                statuses. Provide flexibility for users to add custom fields to
                tasks. These fields might include text fields, dropdowns and
                date pickers. When creating or editing a custom field, include
                an option to mark it as "required." Easily add or delete any of
                these settings during anytime of the project.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/setting-status.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/project-value.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Project Value</h3>
              <p class="landing-single-content">
                Project value, often referred to as “project valuation,” is a
                crucial financial metric used to determine the worth or economic
                significance of a specific project. Company admin or managers
                can note down the value the project has been taken under. They
                also have the ability to note down the amount, the client has
                paid to a particular project and the remaining balance which
                needs to be paid to the same. Please note that this section is
                only displayed to Company admin/managers"
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/project-value.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Project Timer</h3>
              <p class="landing-single-content">
                See tasks allocated on any particular day to the users in a
                project and the time taken for each task. This is specific to
                the company admin and the project managers. Number of hours
                worked on any given task by a user can be viewed in the My
                Report and My Time. The users cannot view the Project Timer
                page. Update or delete the task timing by clicking the "Update"
                button. In the “My Time and My Report, filters such as the
                user’s dropdown and a range of dates are available.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/project-timer.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts Project Summary - Clear Insights and Progress Tracking",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Get clear insights with Focts Project Summary. Displaying start and end dates, five tabs - users, completed tasks, incomplete tasks, overdue tasks, and managers. Recent progress with dates for easy comprehension and tracking.",
      },
      {
        name: "keywords",
        content:
          "Focts, Project Summary, Insights, Progress Tracking, Clear Reading, Easy Comprehension, Start and End Dates, Users, Completed Tasks, Incomplete Tasks, Overdue Tasks, Managers, Recent Progress, Easy to Track",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
