<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-start justify-center pl-3 pl-md-15"
      style="height: 500px"
    >
      <h3 class="white--text font-weight-medium text-h5 text-md-h2">
        Overview
      </h3>
      <p class="white--text font-weight-medium text-body-2 pt-5">
        The perfect view to fit any workflow
      </p>
    </div>
    <v-container>
      <div class="page-center">
        <p class="pt-10 pb-10">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dolor
          nulla, laoreet blandit elit in, mattis fringilla est. Mauris vulputate
          nulla id arcu iaculis, non scelerisque magna congue. Donec a laoreet
          eros, a viverra massa. Aliquam placerat consectetur felis in
          consequat. Nullam scelerisque quis metus non imperdiet. Nam a luctus
          nisi. Nam volutpat pharetra urna et iaculis. Donec et scelerisque
          velit. Suspendisse feugiat posuere hendrerit.
        </p>
        <v-row class="align-center">
          <v-col cols="12">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              dolor nulla, laoreet blandit elit in, mattis fringilla est. Mauris
              vulputate nulla id arcu iaculis, non scelerisque magna congue.
              Donec a laoreet eros, a viverra massa. Aliquam placerat
              consectetur felis in consequat.
            </p>
            <p>
              Nam volutpat pharetra urna et iaculis. Donec et scelerisque velit.
              Suspendisse feugiat posuere hendrerit. Pellentesque in dui id est
              fermentum efficitur non in ligula. Etiam id velit ipsum. In congue
              enim mattis, tincidunt mi non, pretium ligula. Praesent id dolor
              nunc. Praesent lobortis ex eu pellentesque ultrices. Fusce eu erat
              justo. Suspendisse potenti. Etiam ut ante egestas, eleifend nulla
              quis, luctus diam. Donec eu mattis enim. Donec nulla neque,
              fringilla at eros vel, accumsan dapibus mi. Vestibulum sed
              sollicitudin libero. Interdum et malesuada fames ac ante ipsum
              primis in faucibus.
            </p>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="12">
            <h3 class="font-weight-bold text-h5">Lorem ipsum dolor</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              dolor nulla, laoreet blandit elit in, mattis fringilla est. Mauris
              vulputate nulla id arcu iaculis, non scelerisque magna congue.
              Donec a laoreet eros, a viverra massa. Aliquam placerat
              consectetur felis in consequat.
            </p>
            <p>
              Nam volutpat pharetra urna et iaculis. Donec et scelerisque velit.
              Suspendisse feugiat posuere hendrerit. Pellentesque in dui id est
              fermentum efficitur non in ligula. Etiam id velit ipsum. In congue
              enim mattis, tincidunt mi non, pretium ligula. Praesent id dolor
              nunc. Praesent lobortis ex eu pellentesque ultrices. Fusce eu erat
              justo. Suspendisse potenti. Etiam ut ante egestas, eleifend nulla
              quis, luctus diam. Donec eu mattis enim. Donec nulla neque,
              fringilla at eros vel, accumsan dapibus mi. Vestibulum sed
              sollicitudin libero. Interdum et malesuada fames ac ante ipsum
              primis in faucibus.
            </p>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="12">
            <h3 class="font-weight-bold text-h5">Lorem ipsum dolor</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              dolor nulla, laoreet blandit elit in, mattis fringilla est. Mauris
              vulputate nulla id arcu iaculis, non scelerisque magna congue.
              Donec a laoreet eros, a viverra massa. Aliquam placerat
              consectetur felis in consequat.
            </p>
            <p>
              Nam volutpat pharetra urna et iaculis. Donec et scelerisque velit.
              Suspendisse feugiat posuere hendrerit. Pellentesque in dui id est
              fermentum efficitur non in ligula. Etiam id velit ipsum. In congue
              enim mattis, tincidunt mi non, pretium ligula. Praesent id dolor
              nunc. Praesent lobortis ex eu pellentesque ultrices. Fusce eu erat
              justo. Suspendisse potenti. Etiam ut ante egestas, eleifend nulla
              quis, luctus diam. Donec eu mattis enim. Donec nulla neque,
              fringilla at eros vel, accumsan dapibus mi. Vestibulum sed
              sollicitudin libero. Interdum et malesuada fames ac ante ipsum
              primis in faucibus.
            </p>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
