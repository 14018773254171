<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3 class="white--text font-weight-medium text-h3 text-md-h2 text-center">
        Contact Us
      </h3>
    </div>
    <v-container>
      <div class="page-center">
        <h1 class="text-center text-h2 font-weight-medium">Get in Touch</h1>
        <v-card class="elevation-0 pa-5 paragraph-alignment transparent">
          <v-row class="align-center">
            <v-col cols="12" sm="6" md="6">
              <img
                src="@/assets/cotact-us-1-min.jpg"
                alt="Focts Views"
                width="100%"
                style="border: 0"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <p class="pt-3 text-left text-h6">
                You can email us
                <a
                  href="mailto:support@focts.com"
                  class="text-decoration-none contact-href"
                  >support@focts.com</a
                >.<br />
                We will get back to you as soon as we can.
              </p>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts Contact Us - Connect for Seamless Productivity",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Reach out to Focts Contact Us for seamless productivity support. Our team is ready to assist you. Connect, inquire, and elevate your productivity journey effortlessly!",
      },
      {
        name: "keywords",
        content:
          "Focts Contact Us, Productivity Support, Connect, Inquire, Assistance, Seamless Productivity, Team Support, Elevate Your Journey, Get in Touch, Explore Now, Contact Information, Reach Out",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.contact-href {
  color: #2f71de;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 2em auto;
  line-height: 1.6;
}
</style>
