@import '~@/assets/scss/global.scss';

@import "src/assets/scss/landing-page.scss";
.slider-height {
  height: 400px;
}

@media (max-width: 991px) {
  .slider-height {
    height: auto;
  }
}
