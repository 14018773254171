<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        User Interface
      </h3>
    </div>

    <div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Layout Colour</h3>
              <p class="landing-single-content">
                Change the display color of the application to suit your taste
                and need. There are 9 themes available and they are light mode,
                dark mode, purple, pink, dark brown, teal, orange, deep purple,
                and indigo. You can change the themes anytime during the
                project. Individual users can choose the theme to their
                preference.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/layout-display-crm.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/dark-light-mode.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Dark Mode & Light Mode</h3>
              <p class="landing-single-content">
                Users have the option to switch between dark and light themes to
                accommodate their personal preferences and environmental
                conditions. Light themes are usually chosen for readability in
                well-lit environments whereas dark themes are known for their
                sleek and modern appearance.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/dark-light-mode.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Quick Search</h3>
              <p class="landing-single-content">
                Implementing a global search feature that searches across all
                project and task for comprehensive results. to provide clear and
                user-friendly search functionality with relevant autocomplete
                suggestions to enhance the user experience in your project and
                task.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/Common-search.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/All-companies.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">All Companies</h3>
              <p class="landing-single-content">
                Easily explore and navigate through various companies,
                effortlessly choosing the workspace or project that suits your
                needs. Our platform ensures a user-friendly experience,
                simplifying your journey to productivity. This helps you to
                organize your projects into different companies without the
                clutter.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/All-companies.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Pinned Tasks</h3>
              <p class="landing-single-content">
                Experience the convenience of Pinned Tasks: Your go-to solution
                for prioritizing and managing important assignments
                effortlessly. Boost your productivity and maintain your
                workflow’s momentum by having key tasks readily accessible. Stay
                on top of your game with ease and efficiency. This feature is
                accessible from the top navigation bar.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/Pinned-task.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/feature-module-crm.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Featured Module</h3>
              <p class="landing-single-content">
                A user can request a feature to be added on the website by
                clicking on the Add feature function. The user has to give a
                title and a description to explain the feature they think would
                benefit them and other users. This is then displayed in the list
                of features. There is a like/dislike icon where other users can
                show their approval of the feature requested. The Focts team
                would select the feature with the highest number of likes and
                implement it.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/feature-module-crm.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts - Personalize Your Experience with 9 Theme Options",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Customize Focts to your liking. Choose from 9 themes light, dark, purple, pink, dark brown, teal, orange, deep purple, and indigo. Change themes anytime during your project. Individual users can personalize their experience with their preferred theme.",
      },
      {
        name: "keywords",
        content:
          "Focts, Theme Options, Personalization, Customize, Light Mode, Dark Mode, Purple Theme, Pink Theme, Dark Brown Theme, Teal Theme, Orange Theme, Deep Purple Theme, Indigo Theme, Project, Individual Users",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
