<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        Holiday Management
      </h3>
    </div>
    <div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/leave-request.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Apply Leave</h3>
              <p class="landing-single-content">
                Users can apply leave for themselves through this tool. State
                the reason, choose the dates and the type of leave. It’s that
                simple. Once the leave is approved it will be displayed in the
                header section. Managers or the company admin can apply leave
                for the other users in the company.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/leave-request.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Filter</h3>
              <p class="landing-single-content">
                Collect information on the number of leave taken by a single
                user within a range of dates by simply applying the filter
                option. The user filter enabling managers to view requests by
                users. Multiple filter options like "filter by user," "filter by
                month," and "filter by status" are available to company manager
                and admin on the number of leaves taken, but for company users
                only "Filter by month" will be shown. There is an additional
                view called the day and week view. Users can change the views
                for their convenience. The user filter enabling managers to view
                requests by users.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/holiday-management-filter.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/holiday-status.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Status</h3>
              <p class="landing-single-content">
                The Approved and Disapproved filters in holiday management, is
                for tracking and managing the status of holiday requests. Once
                the leave is approved it will be displayed in the headers. The
                company manager/company admin can approve or disapprove a leave
                applied by the user. Once the leave status is updated, the user
                will get a notification to their inbox and email. If the leave
                request status is in the disapproved state, the company
                admin/manager can delete the leave request.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/holiday-status.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Members Absent</h3>
              <p class="landing-single-content">
                Users absent are displayed on the header nearby the settings
                icon. By clicking “Members Absent” icon, absentees appear at the
                bottom of the page.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/member-absent.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts Leave Management - Easy Self and Team Leave",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Simplify leave management with Focts. Users easily apply leave for themselves and state reason, choose dates, and select leave type. Approved leave displayed in the header section. Managers and company admins can apply leave for other users in the company for seamless team management.",
      },
      {
        name: "keywords",
        content:
          "Focts, Leave Management, Self Leave, Team Leave, Apply Leave, Reason, Dates, Leave Type, Approved Leave, Header Section, Managers, Company Admins, Seamless Management, Team Collaboration",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
