// ╔═╗╦  ╔═╗╔╗ ╔═╗╦    ╔═╗╔═╗╔═╗  ╔═╗╔╗╔╔╦╗  ╦╔╗╔╔═╗╦  ╦ ╦╔═╗╦╔═╗╔╗╔  ╔═╗╦  ╔═╗╔╗ ╔═╗╦    ╔═╗╦╦  ╔═╗╔═╗
// ║ ╦║  ║ ║╠╩╗╠═╣║    ║  ╚═╗╚═╗  ╠═╣║║║ ║║  ║║║║║  ║  ║ ║╚═╗║║ ║║║║  ║ ╦║  ║ ║╠╩╗╠═╣║    ╠╣ ║║  ║╣ ╚═╗
// ╚═╝╩═╝╚═╝╚═╝╩ ╩╩═╝  ╚═╝╚═╝╚═╝  ╩ ╩╝╚╝═╩╝  ╩╝╚╝╚═╝╩═╝╚═╝╚═╝╩╚═╝╝╚╝  ╚═╝╩═╝╚═╝╚═╝╩ ╩╩═╝  ╚  ╩╩═╝╚═╝╚═╝
// @import '~normalize.css/normalize';
@import "./styleguide/colors.scss";

body {
  font-size: 14px;
}
.v-picker__title__btn.v-date-picker-title__date.v-picker__title__btn--active {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  line-height: 1.3;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
}

h1 {
  font-size: 4.2rem;
  line-height: 110%;
  margin: 2.8rem 0 1.68rem 0;
}

h2 {
  font-size: 3.56rem;
  line-height: 110%;
  margin: 2.3733333333rem 0 1.424rem 0;
}

h3 {
  font-size: 2.92rem;
  line-height: 110%;
  margin: 1.9466666667rem 0 1.168rem 0;
}

h4 {
  font-size: 2.28rem;
  line-height: 110%;
  margin: 1.52rem 0 0.912rem 0;
}

h5 {
  font-size: 1.64rem;
  line-height: 110%;
  margin: 1.0933333333rem 0 0.656rem 0;
}

h6 {
  font-size: 1.15rem;
  line-height: 110%;
  margin: 0.7666666667rem 0 0.46rem 0;
}

.v-middle {
  vertical-align: middle;
}

.v-link {
  cursor: pointer;
}

.v-application .theme--dark.primary--text { color: white !important; }

.v-input .v-label {
  // text-transform: uppercase;
  font-size: 14px;
}

.horizontal-dividing-header {
  display: inline-block;
  margin-bottom: 15px;

  .header {
    margin-bottom: 2px;
  }

  hr {
    margin-left: 0;
    max-width: 50%;
    //border: 2px solid $light-blue;
  }
}

.theme--light {
  &.v-application {
    background: $bg-color !important;
  }

  .gradient-button {
    background-color: $btn-bg-color;
    color: $light-theme-text-color;
  }

  &.left-navigation-item {
    &.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: #fff !important;

      .v-icon {
        color: #fff;
      }
    }
  }
}

.theme--dark {
  a {
    color: #fff !important;
  }
  // .horizontal-dividing-header {
  //   .header {
  //     color: $dark-theme-text-color;
  //   }
  // }
}



.form-control {
  border: 1px solid;
}

.overflow-y-auto {
  overflow-y: auto;
}

.right-content-sidebar {
  position: absolute;
  right: 0;
}

.v-text-field.v-text-field--enclosed .v-text-field__details { display: none; }

// credit card dailog box design in that npm plugin
.vcc-form-group {
  padding: 10px;
  padding-left: 10px;
  display: flex;

  .vcc-label {
    width: 50%;
  }

  input,
  select,
  textarea {
    border: 2px solid #000;
    padding-left: 10px;
    width: 50%;

  }
}

.theme-options div {
  height: 20px;
  width: 20px;
  margin: 10px;
  border-radius: 6px;

}
.theme-options {
  display: flex;
  margin-top: 2em;
  margin-top: 10em;
  padding-left: 18em;
}

.dark-lite-hidden .dark-switch { display: none; }

button.brightness_icon.hide-icon {
  display: none;
}

.theme--light input:-webkit-autofill,
.theme--light input:-webkit-autofill:hover,
.theme--light input:-webkit-autofill:focus,
.theme--light input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000 !important;
}

.theme--dark input:-webkit-autofill,
.theme--dark input:-webkit-autofill:hover,
.theme--dark input:-webkit-autofill:focus,
.theme--dark input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
}



.v-list-item--active.v-list-item.v-list-item--link, .v-tab.v-tab--active, .v-tab.v-tab--active .v-list-item.v-list-item--link.theme--light { color: #ffffff !important; }