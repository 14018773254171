html {
  overflow: unset;
}
body {
  overflow-y: auto;
  overflow-x: hidden;
}

body.has-scroll {
  overflow: hidden;
}
.v-text-field .v-input__icon--clear {
  opacity: 1 !important;
}


.card-differentiate .v-text-field--solo > .v-input__control > .v-input__slot {
  background: transparent !important;
}
.page-center {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
header.landing-page.v-toolbar.v-app-bar.v-app-bar--fixed {
  position: absolute !important;
  top: 0;
  background-color: transparent;
}
header.landing-page.v-toolbar.v-app-bar.v-app-bar--fixed.v-app-bar--is-scrolled {
  position: fixed !important;
  top: 0;
  background-color: #ffffff;
}
.header-nav-bar a {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.header-button-text {
  font-size: 14px !important;
  letter-spacing: 1px !important;
}

.process-icons {
  font-size: 50px !important;
}

.connect-goals,
.my-work-landing {
  background-color: #f6f5ff;
}

.sign-up-button {
  background-color: #333 !important;
  color: #ffffff !important;
}

.additional-landing-content {
  background-color: #4e4d4d;
}

.hero-section-group {
  background: linear-gradient(45deg, #488fed 0%, #4286db 51%, #2f71de 100%);
  height: 600px;
  background-size: cover;
  width: 100%;
  background-position: bottom;
  p.hero-para {
    justify-content: center;
    align-items: center;
    display: flex;
    color: #ffffff;
    font-size: 20px;
  }
  h1.hero-title {
    font-size: 50px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    color: #ffffff;
    width: 100%;
    max-width: 75%;
    margin: 0 auto;
  }
  .hero-image-section {
    position: relative;
    top: 6em;
  }
}

.italic-style {
  font-style: italic;
}

.landing-heading {
  font-size: 60px;
}

.what-focts {
  padding: 4em 0 0;
}

.why-focts-group {
  padding: 2em 0;
}

.why-focts-content {
  font-size: 15px;
  max-width: 75%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.heading-why-focts {
  font-size: 24px;
  font-weight: 600;
}

.content-why-focts,
.my-work-content {
  font-size: 15px;
  max-width: 90%;
  padding: 10px 0;
}

.landing-text {
  font-size: 15px;
  line-height: 1.8;
  width: 100%;
  max-width: 90%;
}

.card-differentiate
  .theme--dark.v-text-field--solo
  > .v-input__control
  > .v-input__slot {
  background: transparent;
  background-color: transparent;
}
.static-primary {
  background-color: #2f71de !important;
  color: #ffffff !important;
}
.static-secondary {
  background-color: #548dec !important;
  color: #ffffff !important;
}
.static-white {
  background-color: #ffffff !important;
  color: #ffffff !important;
}
.remove-dark .v-application .white--text {
  color: #ffffff !important;
  caret-color: #ffffff !important;
}
.remove-dark .v-application .white {
  background-color: #ffffff !important;
  color: #000 !important;
  border-color: #ffffff !important;
}
.remove-dark .v-application .black--text {
  color: #000 !important;
  caret-color: #000 !important;
}
.image-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.home-slider-section .v-tab.v-tab--active,
.v-application .gradient-button.login-submit-button {
  background-color: #2f71de !important;
  border-color: #2f71de !important;
}

.v-tooltip {
  z-index: 10 !important;
}

.list-subtask .out-chip-width {
  width: 100px;
}

.theme--dark .apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid #000000;
  background: #000000;
}
.theme--dark .apexcharts-menu {
  background: #000;
}
.theme--dark .apexcharts-text tspan,
.theme--dark text {
  fill: #ffffff !important;
}
.graphsection {
  width: 98%;
}
.theme--dark .apexcharts-theme-light .apexcharts-menu-item:hover {
  background: #333;
}
.theme--dark
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background: #000000;
  border-bottom: 1px solid #cccccc;
}

.apexcharts-canvas .apexcharts-element-hidden {
  display: none;
}
.apexcharts-toolbar {
  z-index: 2 !important;
}

.v-select.v-text-field input[readonly="readonly"] {
  display: none;
}

.first-character::first-letter,
.comments-summary p:first-letter {
  text-transform: uppercase;
}

.comments-summary {
  padding-top: 1.5em;
  word-break: break-word;
}

.workspace-projects .v-tabs-slider-wrapper {
  width: 90px !important;
}
.theme--dark.v-application {
  background: #1e1e1e !important;
}
.theme--light.v-application {
  background: #fcfcfd !important;
}
.footer {
  background: linear-gradient(3deg, #1f53bc 0%, #1055eb 100%);
  width: 100%;
  padding: 4em 0;
}

.footer-menu-text-size {
  font-size: 14.5px;
  letter-spacing: 0.5px;
}

.footer-menu div {
  padding: 0 !important;
}

.v-tab {
  text-transform: uppercase;
}
.join-focts-content {
  width: 90%;
  margin: 0 auto;
  line-height: 1.8;
}
.join-focts-contentt {
  width: 100%;
  margin: 0 auto;
  line-height: 1.8;
  font-size: 15px;
}

.join-focts-heading {
  line-height: 1.3 !important;
}

.visualize-work {
  background: linear-gradient(45deg, #488fed 0%, #4286db 51%, #2f71de 100%);
  padding: 3em 0;
}

.content-why-focts {
  font-size: 15px;
  max-width: 90%;
  padding: 10px 0;
}

p.visualize-content {
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  font-size: 15px;
}
.visualize-chip {
  font-size: 16px !important;
}

img {
  border: 1px solid #cccccc;
}

.row {
  margin: 0 !important;
}

.word-break {
  word-break: break-word;
}

.v-menu.add-label-menu.v-menu--attached .v-menu__content {
  width: 300px !important;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}
.v-menu.inside-add-label.v-menu--attached .v-menu__content {
  width: 280px !important;
  overflow-y: hidden;
}

.week-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-menu__content::-webkit-scrollbar {
  height: 10px;
  border-radius: 10px;
  width: 10px;
}

.v-menu__content::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 10px;
}

.v-menu__content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.v-menu__content::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}

.inbox-chip-width span.v-chip__content {
  width: 80px;
}

.today-chip-width span.v-chip__content {
  width: 50px;
}

.list-subtask.today-chip-width span.v-chip__content {
  width: 100px;
}

.week-chip-width span.v-chip__content {
  width: 50px;
}

.landing-single-top {
  font-size: 15px;
  width: 100%;
  margin: 0 auto;
  max-width: 55%;
  text-align: center;
}

.landing-single-back {
  background-color: #f6f5ff;
  padding: 4em;
}

.landing-single-white {
  background-color: #ffffff;
  padding: 4em;
}

.black-logo-header {
  display: none;
}

header.landing-page.v-app-bar--is-scrolled .black-logo-header {
  display: block;
}

header.landing-page.v-app-bar--is-scrolled .white-logo-header {
  display: none;
}

header.landing-page.v-app-bar--is-scrolled .header-nav-bar .top-menu,
header.landing-page.v-app-bar--is-scrolled .header-nav-bar .mobile-menu,
header.landing-page.v-app-bar--is-scrolled .header-nav-bar .top-menu i {
  color: #000000;
}
.faq-section button.v-expansion-panel-header,
.faq-section .v-expansion-panel-content {
  font-size: 15px;
}

header.landing-page .header-nav-bar .top-menu,
header.landing-page .header-nav-bar .mobile-menu,
header.landing-page .header-nav-bar .top-menu i {
  color: #ffffff;
}

.v-list.static-menubar .v-list-item__content,
.v-list.static-menubar .v-list-item__title {
  font-size: 14px;
  text-transform: uppercase;
}

.landing-single-content {
  font-size: 15px;
  width: 100%;
  max-width: 85%;
}

.v-btn--absolute.v-btn--bottom,
.v-btn--fixed.v-btn--bottom {
  bottom: 1em !important;
  right: 1.5em;
}

.v-list.projects-sidebar.v-sheet {
  padding-bottom: 4em;
}

@media (min-width: 600px) and (max-width: 959px) {
  .inbox-chip-width span.v-chip__content {
    width: 140px;
  }
  .today-chip-width span.v-chip__content {
    width: 125px;
  }
}

.row.login-page .primary {
  background-color: #2f71de !important;
  border-color: #2f71de !important;
}

@media (min-width: 480px) and (max-width: 599px) {
  .inbox-chip-width span.v-chip__content {
    width: 100px;
  }
  .today-chip-width span.v-chip__content {
    width: 60px;
  }
}

@media (max-width: 480px) {
  .inbox-chip-width span.v-chip__content {
    width: 100px;
  }
}

@media (min-width: 2500px) {
  .container {
    height: 100vh;
  }
}

@media (max-width: 991px) {
  .container {
    padding: 0;
  }
  .page-center {
    padding: 1em;
  }
}

@media (max-width: 959px) {
  .theme--light.v-application {
    background: #ffffff !important;
  }

  .why-focts-content,
  .landing-text,
  .content-why-focts,
  p.visualize-content,
  .landing-single-content {
    max-width: 100%;
  }
  .header-nav-bar a {
    font-weight: 300;
  }
  .roles-table-scroll {
    overflow-x: scroll;
  }
}

@media (max-width: 767px) {
  .v-autocomplete__content.v-menu__content {
    top: 117px !important;
  }
  .footer {
    height: auto;
    background-position: unset;
  }

  .list-select-dropdown .v-input__control > .v-input__slot {
    padding: 0 !important;
  }

  .why-focts-group {
    padding: 0;
  }

  .apexcharts-toolbar {
    z-index: 5 !important;
    justify-content: unset !important;
  }
  nav.elevation-5.navigation-drawer-scroll.toggle-add-sidebar.mobile-menu-leftBar
    .v-navigation-drawer__content {
    max-height: 1000px;
  }

  .landing-single-top {
    max-width: 85%;
  }

  .landing-single-back,
  .landing-single-white {
    padding: 0;
  }

  .landing-single-content,
  .content-why-focts {
    max-width: 100%;
  }

  .v-menu.add-label-menu.v-menu--attached .v-menu__content {
    min-width: 90% !important;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  }
  .v-menu.inside-add-label.v-menu--attached .v-menu__content {
    min-width: 90% !important;
  }

  .v-btn--absolute.v-btn--bottom,
  .v-btn--fixed.v-btn--bottom {
    bottom: 1em !important;
  }

  .mobile-content-logo {
    padding-top: 8em !important;
  }

  .stick-header-logo {
    position: fixed;
    background-color: #fff;
    top: 0;
    width: 100%;
    z-index: 999;
    border-bottom: 2px solid #f7f7f7;
  }

  div#rc-anchor-container {
    width: auto !important;
  }

  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    opacity: 1 !important;
  }

  .join-focts-content {
    width: 100%;
  }
  .hero-section-group h1.hero-title {
    font-size: 35px;
    text-align: center;
    max-width: 100%;
  }

  .hero-section-group p.hero-para {
    font-size: 18px;
    max-width: 90%;
    width: 100%;
    margin: 10px auto;
  }

  th.text-start.sortable {
    padding: 10px !important;
  }

  .row {
    margin: 0 !important;
  }
}

@media (max-width: 600px) {
  .col-xl,
  .col-xl-auto,
  .col-xl-12,
  .col-xl-11,
  .col-xl-10,
  .col-xl-9,
  .col-xl-8,
  .col-xl-7,
  .col-xl-6,
  .col-xl-5,
  .col-xl-4,
  .col-xl-3,
  .col-xl-2,
  .col-xl-1,
  .col-lg,
  .col-lg-auto,
  .col-lg-12,
  .col-lg-11,
  .col-lg-10,
  .col-lg-9,
  .col-lg-8,
  .col-lg-7,
  .col-lg-6,
  .col-lg-5,
  .col-lg-4,
  .col-lg-3,
  .col-lg-2,
  .col-lg-1,
  .col-md,
  .col-md-auto,
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1,
  .col-sm,
  .col-sm-auto,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1,
  .col,
  .col-auto,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    padding: 5px 10px;
  }
}

@media (max-width: 480px) {
  .v-application--is-ltr .v-data-table .v-data-footer__select {
    margin-left: 0px;
    margin-right: 0px;
  }
  .v-application--is-ltr .v-data-table .v-data-footer__select .v-select {
    margin: 13px 0 13px 13px;
  }
  .v-application--is-ltr .v-data-table .v-data-footer__pagination {
    margin: 0 0px 0 4px;
  }
  .v-application--is-ltr
    .v-data-table
    .v-data-footer__icons-before
    .v-btn:last-child {
    margin-right: 0;
  }
  .v-application--is-ltr
    .v-data-table
    .v-data-footer__icons-after
    .v-btn:first-child {
    margin-left: 0;
  }
  .v-data-table .v-data-footer {
    padding: 0 5px;
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 500px) {
  .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}
