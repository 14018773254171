<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        Customizing My Work
      </h3>
    </div>

    <div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Overview</h3>
              <p class="landing-single-content">
                By clicking on My Work, it immediately directs you to a page
                that displays the Inbox, Today, and Week tabs. Regularly review
                your inbox to process and categorize items into "week" or
                "today" based on their priority and deadlines. Inbox, Today and
                Week are the main attributes to this function. Apply filters to
                view tasks by workspace, project, or user as necessary. A clear
                option is available to the right top of the page to clear any
                data that has been entered. A user-friendly dashboard where
                admins and managers can easily view and manage scheduled tasks
                of all other users too.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/my-work.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/my-work.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Inbox</h3>

              <p class="landing-single-content">
                This view shows tasks that are not assigned a start and an end
                date. Under Inbox, you can add new tasks and assign them to the
                users by clicking on the + symbol. When you add a new task you
                can select the workspace name, the project, title, description,
                start and end date of the task. You can add a complete list of
                users to be assigned to the task. Change the priority and status
                of the task as required. Certain fields marked with “*” are
                mandatory.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/my-work.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Today</h3>
              <p class="landing-single-content">
                By clicking on the Today option, a calendar is displayed where
                the current month and dates are highlighted. Tasks to be done on
                the date chosen are shown. You can also add a new task to a
                particular date under this option.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/Today.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/week.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Week</h3>
              <p class="landing-single-content">
                The purpose of a week view for scheduling tasks is to provide
                users with a convenient and comprehensive way to visualize and
                plan their upcoming week. There is today, tomorrow, and the
                dates that follow. A + symbol is beside the dates to add a new
                task to that date or within a particular set of dates.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/week.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts My Work - Efficient Task Management Dashboard",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Optimize task management with Focts My Work. Review and categorize items in Inbox, Today, and Week tabs. Apply filters for workspace, project, or user. Clear data option for easy navigation. User-friendly dashboard for admins and managers to view and manage scheduled tasks of all users.",
      },
      {
        name: "keywords",
        content:
          "Focts, My Work, Task Management, Dashboard, Inbox, Today, Week, Prioritization, Deadlines, Filters, Workspace, Project, User, Clear Data, User-Friendly, Admins, Managers, Scheduled Tasks",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
