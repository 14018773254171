<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        Time Management
      </h3>
    </div>

    <div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">My Report</h3>
              <p class="landing-single-content">
                Users can see a full report of the number of hours they have
                worked on a project and the individual tasks. This helps in
                managing time and effectively allocating time. Admin and
                Managers can view the number of hours worked by other users, and
                the users can only view their own time report. Update or delete
                the task timing by clicking the "Update" button. Company manager
                is unable to update or delete other user’s time, if they is
                assigned as the project user in a particular project.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/my-report.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/my-time.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">My Time</h3>
              <p class="landing-single-content">
                Users can see a full report of the number of hours they have
                worked on a project and the individual tasks. This helps in
                managing time and effectively allocating time. Admin and
                Managers can view the number of hours worked by other users, and
                the users can only view their own time report. Update or delete
                the task timing by clicking the "Update" button. While filtering
                individual user, shift hours will display along with the task.
                Company manager is unable to update or delete other user’s time,
                if they is assigned as the project user in a particular project.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/my-time.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">
                My Time and My Report Filter
              </h3>
              <p class="landing-single-content">
                In the “My Time and My Report, filters such as the user’s
                dropdown and a range of dates are available. The user’s dropdown
                allows users to filter individual tasks. Meanwhile, the range of
                dates filter enables you to focus on specific dates. By applying
                these filters, a list of tasks along with task name, project
                name, user name, the number of worked hours and the action to be
                taken are displayed.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/my-time.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts Time Reports - Efficient Time Management Solution",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Enhance time management with Focts Time Reports. View project and task hours, allocate time effectively. Admins and Managers access others user`s hours, users view their own reports. Update or delete task timing with ease. Company managers can`t modify other user`s time in assigned projects.",
      },
      {
        name: "keywords",
        content:
          "Focts, Time Reports, Time Management, Project Hours, Task Hours, Allocate Time, Admins, Managers, Users, Update Timing, Delete Timing, Company Manager, Efficient Solution",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
