<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        Team Collaboration
      </h3>
    </div>
    <div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Chat</h3>
              <p class="landing-single-content">
                Team collaboration is a communication and project management
                approach that focuses on teamwork, creativeness, and inclusion
                to achieve goals. Here group and individual chats present to
                communicate with each user.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/single-chat.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/single-chat.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Direct Chat</h3>
              <p class="landing-single-content">
                Users can send a direct message to others users/admin/managers.
                It is like a personal chat. The chat messages can be edited and
                deleted. Users cannot chat with deactivated users, but only the
                history of messages can be seen.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/single-chat.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Group Chat</h3>
              <p class="landing-single-content">
                The manager or the admin can create a group chat to send
                messages to multiple users at a single time. Add/remove users
                from the group or delete the entire group chat option that is
                available to the managers and admin. Easily view all the users
                in a particular group by clicking the group info.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/group-chat.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/notification.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Notification</h3>
              <p class="landing-single-content">
                The Inbox is your centralized hub for staying up-to-date with
                all the latest developments and changes related to your tasks
                and projects. It simplifies task management by ensuring that
                you’re never out of the loop. Task updates are transparent and
                accessible to the entire team, fostering collaboration and
                reducing confusion. No more digging through emails or checking
                multiple task lists. The inbox streamlines communication and
                keeps everything in one place.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/notification.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts Team Collaboration - Enhance Communication and Productivity",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Elevate teamwork with Focts. A collaborative approach for communication, project management, and goal achievement. Group and individual chats for seamless user interaction and creative collaboration.",
      },
      {
        name: "keywords",
        content:
          "Focts, Team Collaboration, Communication, Project Management, Goal Achievement, Teamwork, Creativeness, Inclusion, Group Chats, Individual Chats, Productivity, User Interaction, Creative Collaboration",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
