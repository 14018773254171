<template>
  <div>
    <div
      class="static-primary d-flex flex-column align-center justify-center"
      style="height: 500px"
    >
      <h3
        class="ma-4 white--text font-weight-medium text-h3 text-md-h2 text-center word-break"
      >
        Task Management
      </h3>
    </div>

    <div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/Task.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Task Overview</h3>
              <p class="landing-single-content">
                This includes a task title and description, a to-do list, a
                comments section, task type and priority classification, status
                tracking, estimated time for completion, action required, and a
                record of completion status. Additionally, tasks can be assigned
                to specific users, labeled for easy categorization, and
                customized with additional fields. Furthermore, users have the
                capability to initiate timers for tracking the task's start and
                stop times. There is a option to create a subtask or change the
                subtask to normal task by clicking the setting button near
                pinned task icon.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/Task.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Task CheckList</h3>
              <p class="landing-single-content">
                To-do lists help you focus on what needs to be done. One can add
                a to-do list on the task page by clicking the + button. Edit or
                delete the to-do items under each task. Mark to-do as completed
                by simply clicking on the associated checkboxes. Toggle the
                “Hide completed” option to hide the completed tasks. A drag and
                drop option in a to-do list is a feature that allows you to
                effortlessly rearrange and prioritize your tasks.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/tasks-checklists.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/task-comments.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Task Comments</h3>
              <p class="landing-single-content">
                The comments section in a task page allows users to provide
                feedback, ask questions, or share information related to the
                task. Comments can be in text or image form. Comments on any
                task will be visible to anyone who has access to that project.
                Ability to edit, or delete comments during anytime of the task.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/task-comments.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Task Type</h3>
              <p class="landing-single-content">
                While creating the task, default task type will be displayed.
                Task type can be changed during anytime of the task. Task type
                is very important when it comes to filtering tasks within a
                project. The task type is used to differentiate what kind of
                task you are creating, for eg an issue, idea or just a plain
                task.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/Task-type.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/Task-status.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Status</h3>
              <p class="landing-single-content">
                While creating the task, default Status will be displayed. The
                status presented within a task refers to a representation of the
                task’s progress or current state. Filtering tasks using the
                “status filter” in project management involves the ability to
                sort and display tasks based on their current state. Status can
                be changed during anytime of the task.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/Task-status.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Priority</h3>
              <p class="landing-single-content">
                Priority represents the urgency of a task; it helps individuals
                or teams determine which tasks should be addressed first. While
                creating the task, default priority will be display. By applying
                a priority filter, you can quickly isolate and view critical
                task. Priority can be changed during anytime of the task.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/Task-priority.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/custom-field.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Custom Fields</h3>
              <p class="landing-single-content">
                As long as any custom fields have the “required” label, they
                must be filled out when creating tasks on the project. Easily
                edit any of these custom fields any time.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/custom-field.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Add labels</h3>
              <p class="landing-single-content">
                Easily add labels in a single task by clicking "Add label"
                button. By adding a label on the particular task, that label is
                stored in the project setting. The search option in labels is
                also available.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/add-labels.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/task-action-required.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Action Required</h3>
              <p class="landing-single-content">
                With the help of this function, you can give the action required
                for the task to someone on the project. Once the action required
                is assigned to a user, they can see that task on their My Work
                section.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/task-action-required.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Estimate Hours</h3>
              <p class="landing-single-content">
                Estimated hours are just the time we are given to know about the
                estimated time needed to complete the task.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/estimate.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/Task-start-end.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Start and End time</h3>
              <p class="landing-single-content">
                The start and end dates for a task define the timeframe within
                which the task is expected to be completed. Filtering tasks
                based on the start and end dates, you can easily isolate tasks
                that are currently active or approaching their deadlines. Due
                date and Current date task will be presented under My work
                “Today” page. Future date task will be presented in the My work
                “Week” page.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/Task-start-end.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-back">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Users</h3>
              <p class="landing-single-content">
                Assigned users are those who are present in the task and may or
                may not have any role in finishing the task. Any kind of action
                can be done only by the assigned users. If any action required
                user presented in assigned user section, you can’t remove them
                from the task.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <img
                src="@/assets/focts-image/task-users.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-single-white">
        <div class="page-center">
          <v-row class="align-center">
            <v-col cols="12" md="6" xs="12" class="d-none d-sm-none d-md-flex">
              <img
                src="@/assets/focts-image/start-time-image.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
            <v-col cols="12" md="6" xs="12">
              <h3 class="font-weight-bold text-h4">Task Timer</h3>
              <p class="landing-single-content">
                Anyone assigned in the task can start and end the time. The
                working time on the particular task will be reflected in the My
                Time section. Edit, or delete the timer during anytime of the
                task by clicking setting button on the right-hand side of
                comments section. If a user has completed a task without adding
                the start and end time of the task, the number of hours worked
                on that particular task can be known by adding the spent time of
                the user. Able to edit, or delete the spent time of the task by
                clicking setting button on the right-hand side of comments
                section.
              </p>
            </v-col>
            <v-col cols="12" md="6" xs="12" class="d-flex d-sm-flex d-md-none">
              <img
                src="@/assets/focts-image/start-time-image.png"
                alt="Focts Views"
                width="100%"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Focts Task Details - Comprehensive Task Management",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Efficient task management with Focts. Task title, description, to-do list, comments, type, priority, status tracking, estimated time, action required, completion record, user assignments, categorization, and customization. Initiate timers for precise tracking. Create subtasks with ease.",
      },
      {
        name: "keywords",
        content:
          "Focts, Task Details, Task Management, To-Do List, Comments, Task Type, Priority, Status Tracking, Estimated Time, Action Required, Completion Record, User Assignments, Categorization, Customization, Timers, Subtasks",
      },
    ],
  },
};
</script>
<style scoped>
.hero-section {
  height: 500px;
}
.paragraph-alignment {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.6;
}
</style>
